import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
  FaWhatsapp,
  FaFacebookF,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className=' text-black p-2 mt-auto shadow-2xl '>
      <div className='flex justify-between items-center'>
        {/* Left Side: Copyright */}
        <p className='text-xs'></p>
        <div className='flex space-x-4'>
          <h3 className='text-xs md:text-md mt-1'>
            Follow us to get latest updates{" "}
          </h3>
          <div className='flex-1'>
            <div className='flex space-x-4'>
              <a
                href='https://www.facebook.com/SRSSOFTWARESPTAC'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-600 hover:text-blue-800'
              >
                <FaFacebookF className='text-2xl' />
              </a>
              <a
                href='https://whatsapp.com/channel/0029VaCM9KAD38CZDxZRlQ0p'
                target='_blank'
                rel='noopener noreferrer'
                className='text-green-600 hover:text-green-800'
              >
                <FaWhatsapp className='text-2xl' />
              </a>
              <a
                href='https://twitter.com/srssoftwares'
                target='_blank'
                rel='noopener noreferrer'
                className='text-black '
              >
                <FaXTwitter className='text-2xl' />
              </a>
              <a
                href='https://linkedin.com/company/srs-softwares'
                target='_blank'
                rel='noopener noreferrer'
                className='text-blue-700 hover:text-blue-900'
              >
                <FaLinkedinIn className='text-2xl' />
              </a>
              <a
                href='https://www.instagram.com/srssoftwares'
                target='_blank'
                rel='noopener noreferrer'
                className='text-pink-600 hover:text-pink-800'
              >
                <FaInstagram className='text-2xl' />
              </a>
              <a
                href='https://youtube.com/@srssoftwares3144'
                target='_blank'
                rel='noopener noreferrer'
                className='text-red-600 hover:text-gray-600'
              >
                <FaYoutube className='text-2xl' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
