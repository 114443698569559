// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import PricingPlans from "./PricingPlans";
// const Hero = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   // Function to open modal
//   const openModal = () => setIsModalOpen(true);

//   // Function to close modal
//   const closeModal = () => setIsModalOpen(false);

//   return (
//     <section
//       id='home'
//       className='relative flex items-center justify-start w-full h-[60vh] bg-cover bg-center'
//       style={{
//         backgroundImage:
//           "url('https://img.freepik.com/free-vector/digital-futuristic-earth-technology-background-with-glowing-lights_1017-23327.jpg')",
//       }}
//     >
//       {/* Text Content */}
//       <div className='relative z-10 px-6 md:px-2 text-white w-full md:w-1/2 text-justify'>
//         <blockquote className='text-md lg:text-3xl font-semibold uppercase mb-4 text-center'>
//           <span className='text-3xl text-yellow-400'>!!!</span> We are changing
//           the world with technology{" "}
//           <span className='text-3xl text-yellow-400'>!!!</span>
//         </blockquote>

//         <blockquote className='text-sm lg:text-md font-semibold text-gray-100 mt-4 leading-relaxed'>
//           Emphasizing the importance of execution in the tech industry, from
//           concept to product. Technology is transforming every aspect of our
//           lives, from how we communicate to how we work, learn, and solve global
//           challenges. Technology is transforming every aspect of our lives, from
//           how we communicate to how we work, learn, and solve global challenges.
//           As we continue to push the boundaries of what’s possible, technology
//           has the potential to create a more efficient, equitable, and
//           sustainable world for future generations.
//         </blockquote>

//         <button
//           onClick={openModal}
//           className='mt-6 z-10 inline-block w-full px-6 py-3 bg-yellow-400 text-black rounded-md text-xs hover:bg-yellow-500 transition duration-200 w-full'
//         >
//           Request a Demo
//         </button>
//       </div>

//       {/* Modal */}
//       {isModalOpen && (
//         <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-10'>
//           <div className='bg-white rounded-lg p-2 max-w-lg w-full m-2'>
//             <div className='flex justify-between items-center'>
//               <h2 className='text-2xl font-semibold'></h2>
//               <button
//                 onClick={closeModal}
//                 className='text-4xl font-bold text-gray-600 mr-5'
//               >
//                 &times;
//               </button>
//             </div>
//             <PricingPlans />
//           </div>
//         </div>
//       )}
//     </section>
//   );
// };

// export default Hero;
import React from "react";

const Hero = () => {
  return (
    <section
      id='home'
      className='relative flex flex-col md:flex-row items-center justify-center md:justify-start w-full mt-[50px] md:mt-[65px] lg:mt-[65px] h-[50vh] md:h-[60vh] bg-cover bg-no-repeat bg-center'
    >
      {/* Background Images */}
      <div
        className='absolute inset-0 bg-cover bg-center hidden sm:block'
        style={{
          backgroundImage:
            "url('https://img.freepik.com/free-vector/digital-futuristic-earth-technology-background-with-glowing-lights_1017-23327.jpg')",
        }}
      ></div>
      <div
        className='absolute inset-0 bg-cover bg-center block sm:hidden'
        style={{
          backgroundImage:
            "url('https://img-cdn.pixlr.com/image-generator/history/676bc1f5bcd2679095317a9b/79721ef8-ae8c-4d87-b737-5656919878c7/preview.webp')",
        }}
      ></div>

      {/* Text Content */}
      <div className='z-10 ml-3 md:px-2 text-white w-full md:w-1/2 md:text-left mt-[50vh] md:mt-0'>
        <blockquote className='text-sm lg:text-2xl font-semibold uppercase mb-2 md:text-left text-black md:text-white'>
          Access your business from anywhere in the world{" "}
        </blockquote>
      </div>
    </section>
  );
};

export default Hero;
