import React from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Footer from "./Footer";
import Features from "./Features";
import AboutUs from "./AboutUs";
import PricingPlans from "./PricingPlans";
import FAQPage from "./FAQ";
import ContactPage from "./Contact";
import WhyChooseUs from "./WhyChooseUs";
import ScrollToTop from "./ScrollToTop";

const index = () => {
  return (
    <div className='bg-[#FFFFFF]'>
      <Navbar />
      <Footer />
      {/* Hero Section */}
      <section id='home' className='flex items-center justify-center '>
        <Hero />
      </section>
      <div className='relative z-10 px-3 md:px-2  w-full mt-2 text-justify'>
        <blockquote className='text-md lg:text-3xl font-semibold uppercase mb-4 text-center'>
          <span className='text-3xl text-yellow-400'>!!!</span> We are changing
          the world with technology{" "}
          <span className='text-3xl text-yellow-400'>!!!</span>
        </blockquote>

        <blockquote className='text-sm lg:text-md font-semibold  mt-4 leading-relaxed'>
          Emphasizing the importance of execution in the tech industry, from
          concept to product. Technology is transforming every aspect of our
          lives, from how we communicate to how we work, learn, and solve global
          challenges. Technology is transforming every aspect of our lives, from
          how we communicate to how we work, learn, and solve global challenges.
          As we continue to push the boundaries of what’s possible, technology
          has the potential to create a more efficient, equitable, and
          sustainable world for future generations.
        </blockquote>
      </div>

      {/* Features Section */}
      <section id='features' className='flex items-center justify-center'>
        <Features />
      </section>
      <section id='aboutUs' className='flex items-center justify-center'>
        <AboutUs />
      </section>
      <section id='whyChooseUs' className='flex items-center justify-center'>
        <WhyChooseUs />
      </section>
      <section id='faq' className='flex items-center justify-center'>
        <FAQPage />
      </section>
      <section id='pricingPlans' className='flex items-center justify-center'>
        <PricingPlans />
      </section>
      <section id='contact' className='flex items-center justify-center'>
        <ContactPage />
      </section>
      {/* Product Section */}
      <section
        id='product'
        className=' bg-white flex items-center justify-center'
      ></section>
      <ScrollToTop />
    </div>
  );
};

export default index;
