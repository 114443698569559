import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/PTACLogo.jpg";
import Footer from "./Footer";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className='bg-[#FFFFFF] fixed top-0 left-0 w-full z-[9999] shadow-2xl mb-1 '>
      <Footer />
      <div className='mx-auto flex justify-between items-center p-1'>
        {/* Logo */}
        <Link
          to='/'
          className='text-white font-bold text-2xl hover:text-gray-200 p-1 rounded-lg'
        >
          <img
            src={Logo}
            alt='Logo'
            className='h-[50px] w-full object-contain md:ml-5 rounded-lg'
          />
        </Link>
        {/* Menu Items for Desktop */}
        <div className='hidden md:flex space-x-4 mr-2'>
          <a
            href='https://www.ptacerp.com/blogs'
            target='_blank'
            rel='noreferrer'
            className='text-white text-sm bg-[#000000] px-4 py-1 rounded shadow-2xl mr-5'
          >
            Blogs
          </a>
          <a
            href='http://cloud.ptacerp.com'
            target='_blank'
            rel='noreferrer'
            className='text-white text-sm bg-[#000000] px-4 py-1 rounded shadow-2xl mr-5'
          >
            Login
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className='md:hidden text-black text-sm font-semibold'>
          <a
            href='https://www.ptacerp.com/blogs'
            target='_blank'
            rel='noreferrer'
            className='text-white text-sm bg-[#000000] px-4 py-1 rounded shadow-2xl mr-5'
          >
            Blogs
          </a>
          <a
            href='http://cloud.ptacerp.com'
            target='_blank'
            rel='noreferrer'
            className='text-white text-sm bg-[#000000] px-4 py-1 rounded shadow-2xl mr-5'
            onClick={toggleMenu}
          >
            Login
          </a>
        </div>
      </div>

      {/* Mobile Menu Panel */}
      {isMenuOpen && (
        <div className='fixed top-10 right-0 w-full h-1/2 bg-gray-200 flex flex-col items-center justify-center space-y-4 z-100'>
          <Link
            to='/login'
            className='text-black text-lg hover:text-gray-200'
            onClick={toggleMenu}
          >
            Login
          </Link>
          <Link
            to='/request-demo'
            className='text-white bg-black px-4 py-1 rounded hover:bg-gray-100'
            onClick={toggleMenu}
          >
            Request a Demo
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
